<template>
  <div v-if="independent && missions && loaded" class="w-full lp:py-5">
    <div v-if="showOtherMonths" class="flex justify-content-between md:pr-7 md:pb-3">
      <div v-if="getNumberCraVisible === 0" class="text-md w-8 lp:text-xl">
        {{
          (craType === constants.CRA_TYPES.PAY ? `Vous n'avez aucune mission en cours pour ce mois` : '') +
          (craType === constants.CRA_TYPES.INVOICE ? `Pour valider votre déclaration de facturation, vous devez valider votre déclaration de paie du mois de ${new Date(Date.UTC(craDate.year, craDate.month, 1)).toLocaleDateString('fr-FR', {month: 'long'}).toUpperCase()}` : '')
        }}
      </div>
      <div v-else-if="getIsBrowserApp" class="text-xl">Mission</div>
      <div v-if="getIsBrowserApp" class="flex align-items-center justify-content-between">
        <i :style="isPreviousMonth ? 'visibility: visible' : 'visibility: hidden'"
           class="mr-3 pi pi-caret-left cursor-pointer text-xl"
           @click="previousMonth"
        />
        <span class="text-md">
          {{
            new Date(Date.UTC(craDate.year, craDate.month, 1)).toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long'
            }).toUpperCase()
          }}
        </span>
        <i :style="isNextMonth ? 'visibility: visible' : 'visibility: hidden'"
           class="ml-3 pi pi-caret-right cursor-pointer text-xl"
           @click="nextMonth"
        />
      </div>
    </div>
    <div v-if="missions.length > 0 && getNumberCraVisible > 0" class="my-4 lp:my-0 lp:border-round lp:p-4 lp:surface-card lp:shadow-2">
      <div v-if="getIsBrowserApp && getNumberCraVisible > 0" style="font-size: 1.25rem">
        CRA :
      </div>
      <!--  todo: double v-if there due to a bug when put missions.length > 0 && getNumberCraVisible > 0 => nothing's print  -->
      <template v-if="!getIsBrowserApp && getNumberCraVisible > 0">
        <Carousel :value="missions" :show-navigators="false" class="flex mt-2 lp:justify-content-center">
          <template #item="slotProps">
<!--            {{ slotProps }}-->
            <cra-details :cra-type="craType" :index-mission="slotProps.index" :mission="slotProps.data" :cra-save-flag="craSaveFlag"
                         :holidays="publicHolidays" :editable="editable"
                         @on-compute-totals="onComputeCraDetailsTotal" @update-mission="updateMission"/>
          </template>
        </Carousel>
      </template>
      <template v-else-if="getIsBrowserApp && getNumberCraVisible > 0">
        <div class="flex justify-content-center mt-2" v-for="(mission, indexMission) in missions" :key="mission.id">
          <cra-details :cra-type="craType" :index-mission="indexMission" :mission="mission" :cra-save-flag="craSaveFlag"
                       :holidays="publicHolidays" :editable="editable"
                       @on-compute-totals="onComputeCraDetailsTotal" @update-mission="updateMission"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CraDetails from "@/components/CraDetails.vue";
import Alert from "@/utils/Alert";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";

export default {
  name: "Cra",
  mixins: [isBrowserAppMixin],
  components: {CraDetails},
  emits: ['numberCra'],
  props: {
    // see the constants CRA_TYPES
    craType: {
      type: String,
      required: true
    },
    craSaveFlag: {
      type: Boolean
    },
    showOtherMonths: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loaded: false,
      publicHolidays: [],
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      missions: state => state.mission.missions,
      settings: state => state.misc.settings,
      independent: state => state.independent.independent,
      localPay: state => state.pay.pay,
      localFacturations: state => state.declarationFacturation.declarationsFacturation,
      craDate: state => state.cra.date
    }),
    getNumberCraVisible() {
      let result = 0
      if (!this.missions || this.missions.length === 0 || !this.craDate) {
        return 0
      }
      this.missions.forEach((mission) => {
        if (this.craType === this.constants.CRA_TYPES.INVOICE) {
          this.localFacturations.forEach((localInvoice) => {
            if (localInvoice.mission === mission.id) {
              result += 1
            }
          })
        } else if (this.craType === this.constants.CRA_TYPES.PAY) {
          if (!this.localPay?.cras) return
          result += 1
        }
      })
      this.$emit('numberCra', result)
      return result
    },
    isPreviousMonth() {
      // todo: definir le spectre de vision d'un admin
      // represente ce que peux voir l'indep
      const independentStartingDate = new Date(this.independent.profil.workContract.startingDate)
      return (this.craDate.year === independentStartingDate.getFullYear() && independentStartingDate.getMonth() <= this.craDate.month - 1) || independentStartingDate.getFullYear() < this.craDate.year
    },
    isNextMonth() {
      // todo: definir le spectre de vision d'un admin
      // represente ce que peux voir l'indep
      const date = new Date()
      // cannot go further than the actual CRA year and month
      if ((this.craDate.year === date.getFullYear() && this.craDate.month + 1 > date.getMonth()) || this.craDate.year > date.getFullYear()) {
        return false
      }
      return true
    },
  },
  watch: {
    async craDate (value) {
      await this.initPublicHolidays(value.year)
    }
  },
  methods: {
    async initPublicHolidays (year) {
      this.publicHolidays = await this.$store.dispatch('publicHolidays/getPublicHolidays', { years: [year] })
    },
    resetComponent () {
      this.loaded = false
    },
    updateMission (currentMission) {
      const missionIndex = this.missions.findIndex((mission) => mission?.id === currentMission?.id)
      if (missionIndex === -1) return
      this.missions[missionIndex] = currentMission
    },
    nextMonth () {
      if (!this.isNextMonth) {
        return
      }

      let data = {
        month: (this.craDate.month + 1) % 12,
        year: ((this.craDate.month + 1) % 12 === 0) ? this.craDate.year + 1 : this.craDate.year
      }
      // todo: this is needed to force component refresh, should rework this later on
      this.loaded = false
      this.$emit('changeCurrentDate', data)
    },
    previousMonth () {
      let data = {
        month: (((this.craDate.month - 1) % 12) + 12) % 12, // Because modulo doesn't work correctly with js
        year: ((((this.craDate.month - 1) % 12) + 12) % 12 === 11) ? this.craDate.year - 1 : this.craDate.year
      }
      // todo: this is needed to force component refresh, should rework this later on
      this.loaded = false
      this.$emit('changeCurrentDate', data)
    },
    async changeCra () {
      this.loaded = false
      if (this.craType === this.constants.CRA_TYPES.PAY) {
        await this.getCurrentCraPay()
      } else if (this.craType === this.constants.CRA_TYPES.INVOICE) {
        await this.getCurrentCraInvoice()
      }
      await this.updateCra()
      this.loaded = true
    },
    updateCra () {
      this.missions.forEach((mission, index) => {
        if (this.craType === this.constants.CRA_TYPES.INVOICE) {
          if (!this.localFacturations || this.localFacturations.length === 0) {
            mission.currentCra = JSON.parse(JSON.stringify(this.newFakeCra(mission, index)))
          } else {
            this.localFacturations.forEach((localInvoice) => {
              if (localInvoice.mission === mission.id &&
                  (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ||
                      mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ||
                      mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE)) {
                let cra = {
                  mission: mission,
                  month: this.craDate.month,
                  year: this.craDate.year,
                  timesheet: localInvoice.timesheet
                }
                cra.color = this.getColorByIndex(index)
                cra.isVisible = true
                mission.currentCra = JSON.parse(JSON.stringify(cra))
              }
              // else if (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
              //   mission.currentCra = JSON.parse(JSON.stringify(this.newFakeCra(mission, index)))
              // }
            })
          }
        } else if (this.craType === this.constants.CRA_TYPES.PAY) {
          if (!this.localPay?.cras) return
          let existingCRA = this.localPay.cras.find((cra) => {
            return cra && cra.mission === mission.id && cra.pay !== null
          })
          if (existingCRA) {
            existingCRA.color = this.getColorByIndex(index)
            existingCRA.isVisible = true
            mission.currentCra = JSON.parse(JSON.stringify(existingCRA))
            return
          }
          // Si le CRA n'existe pas
          let newCRA = this.newFakeCra(mission, index)
          this.localPay.cras?.push(newCRA)
          newCRA.color = this.getColorByIndex(index)
          newCRA.isVisible = true
          mission.currentCra = JSON.parse(JSON.stringify(newCRA))
        }
      })
      this.loaded = true
      this.$store.state.cra.date.loaded = true
    },
    newFakeCra(mission, index) {
      let newCRA = {
        mission: mission.id,
        month: this.craDate.month,
        year: this.craDate.year,
        timesheet: [{
          type: mission.jsonCost.negotiatedRateUnit,
          label: 'Sur site',
          value: [],
          total: 0
        }]
      }
      if (mission.jsonCost.teleworking === this.constants.TELEWORKING_TYPE.FULL
          && mission.jsonCost.teleworkingRate
          && mission.jsonCost.negotiatedRateUnit !== this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
        newCRA.timesheet.push({
          type: mission.jsonCost.negotiatedRateUnit,
          label: 'Télétravail',
          value: [],
          total: 0
        })
      }
      newCRA.color = this.getColorByIndex(index)
      newCRA.isVisible = true
      return newCRA
    },
    async getCurrentCraPay () {
      await this.$store.dispatch('pay/getOnePay', {
        idIndep: this.independent.id,
        month: this.craDate.month,
        year: this.craDate.year
      })
      // no more creation of a custom pay if there's no pay found
    },
    async getCurrentCraInvoice () {
      await this.$store.dispatch('declarationFacturation/getAllDeclarationFacturationByIndep', {
        idIndep: this.independent.id,
        month: this.craDate.month,
        year: this.craDate.year
      })
    },
    getColorByIndex(index) {
      switch (index) {
        case 0:
          return '#62cca9'
        case 1:
          return '#6e8fd7'
        case 2:
          return '#ff7373'
        case 3:
          return '#AD8A64'
        case 4:
          return '#7341B4'
        case 5:
          return '#683C4F'
        case 6:
          return '#CCB69B'
        case 7:
          return '#5D5F71'
        case 8:
          return '#6B3D38'
        default:
          return '#AF1B3F'
      }
    },
    onComputeCraDetailsTotal (payload) {
      if (payload?.forceMissionUpdate) {
        this.updateMission(payload?.mission)
      }

      const getDayValue = (day) => {
        let dayValue = 0
        switch (day) {
          case 1:
            dayValue = 1
            break
          case 2:
            dayValue = 0.5
            break
          case 3:
            dayValue = 0.5
            break
        }
        return dayValue
      }

      let shownAlert = false
      this.missions.forEach((mission) => {
        if (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
            || mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
          const stackedTimesheetValues = [] // todo: for now, only work for sameTimesheet not along multiple missions and timesheet
          let dayRealValue = null
          mission.currentCra.timesheet.forEach((timesheet) => {
            timesheet.total = 0
            timesheet.value.forEach((dayValue, index) => {
              dayRealValue = getDayValue(dayValue)
              if (!stackedTimesheetValues[index]) {
                stackedTimesheetValues[index] = dayRealValue
              } else {
                stackedTimesheetValues[index] += dayRealValue
              }
              // todo: might want to not notify on page opening but only on click
              if (stackedTimesheetValues[index] > 1 && !shownAlert) {
                Alert.warningMessage(this, 'invalidStackDay')
                shownAlert = true
                // timesheet.value[index] = null
              }
              timesheet.total += dayRealValue
            })
          })
        } else if (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE) {
          const stackedTimesheetValues = []
          mission.currentCra.timesheet.forEach((timesheet) => {
            timesheet.total = 0
            timesheet.value.forEach((dayValue, index) => {
              if (dayValue >= 1 && dayValue <= 24) {
                if (!stackedTimesheetValues[index]) {
                  stackedTimesheetValues[index] = dayValue
                } else {
                  stackedTimesheetValues[index] += dayValue
                }
              }
              if (!dayValue) {
                timesheet.value[index] = null
              } else if ((dayValue < 1 || dayValue > 24) && !shownAlert) {
                Alert.errorMessage(this, 'invalidHoraire')
                shownAlert = true
                timesheet.value[index] = null
              } else {
                timesheet.total += dayValue
              }

              if ((stackedTimesheetValues[index] < 1 || stackedTimesheetValues[index] > 24) && !shownAlert) {
                Alert.warningMessage(this, 'invalidStackHoraire')
                shownAlert = true
                // timesheet.value[index] = null
              }
            })
          })
        }
      })

      // let totalHoursPerRemoteDay = 0
      // let totalDaysPerRemoteDay = 0

      let dayValue = 0
      let numberOfHoursPerRemoteDay = 0
      let numberOfDaysPerRemoteDay = 0
      let feeTotalDays = 0
      let restaurantTicketsCount = 0
      let restaurantTicketsPerDay = 0

      const hoursNumberForRestaurantTicket = 5
      const maxHoursPerDay = 7

      this.craDate.days.forEach((day, dayIndex) => {
        numberOfHoursPerRemoteDay = 0
        numberOfDaysPerRemoteDay = 0
        restaurantTicketsPerDay = 0
        this.missions.forEach((mission) => {
          if (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
              || mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
            mission.currentCra.timesheet.forEach((timesheet) => {
              dayValue = getDayValue(timesheet.value[dayIndex])
              numberOfDaysPerRemoteDay += timesheet.label.toLowerCase() === 'sur site' ? 0 : dayValue
              restaurantTicketsPerDay += dayValue
            })
          } else if (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE) {
            mission.currentCra.timesheet.forEach((timesheet) => {
              dayValue = timesheet.value[dayIndex] ?? 0
              numberOfHoursPerRemoteDay += timesheet.label.toLowerCase() === 'sur site' ? 0 : dayValue
              restaurantTicketsPerDay += dayValue >= hoursNumberForRestaurantTicket ? 1 : 0
            })
          }
        })
        if (this.craType === this.constants.CRA_TYPES.PAY) {
          if (restaurantTicketsPerDay >= 1) {
            restaurantTicketsCount += 1
          }
          if (numberOfDaysPerRemoteDay >= 1 || numberOfHoursPerRemoteDay >= maxHoursPerDay) {
            feeTotalDays += 1
          } else {
            if (!!numberOfDaysPerRemoteDay && numberOfDaysPerRemoteDay < 1) {
              numberOfHoursPerRemoteDay += numberOfDaysPerRemoteDay * maxHoursPerDay  // means 0.5 or 0 * hoursPerDay
            }
            if (!!numberOfHoursPerRemoteDay && numberOfHoursPerRemoteDay < maxHoursPerDay) {
              feeTotalDays += numberOfHoursPerRemoteDay / maxHoursPerDay
            } else if (numberOfHoursPerRemoteDay > maxHoursPerDay) { // in the case where the accumulation gives more than the max hours per day
              feeTotalDays += 1
            }
          }
        }
        // totalHoursPerRemoteDay += numberOfHoursPerRemoteDay
        // totalDaysPerRemoteDay += numberOfDaysPerRemoteDay
      })
      // console.log('totalHoursPerRemoteDay', totalHoursPerRemoteDay)
      // console.log('totalDaysPerRemoteDay', totalDaysPerRemoteDay)
      // console.log('feeTotalDays', feeTotalDays)
      if (this.craType === this.constants.CRA_TYPES.PAY) {
        if (this.settings.RH.ticketsRestaurant.value && this.independent.restaurants_tickets) {
          this.$store.dispatch('pay/updateTotalRestaurantTickets', restaurantTicketsCount)
        }
        this.$store.dispatch('pay/updateForfait', (feeTotalDays * this.settings.Paie.avantagesSociaux.teletravail.forfait) / 100)
      }
    }
  }
}
</script>

<style scoped>

</style>
