<template>
  <div v-if="localMission?.currentCra?.isVisible" class="w-full">
    <div v-if="!getIsBrowserApp">
      <div class="flex flex-row justify-content-between align-items-center">
        <div class="flex flex-row align-items-center gap-2 mb-1">
          <p class="mb-0" :style="'color: '+ localMission.currentCra.color">{{ localMission.clientContract.name.toUpperCase() }}</p>
          <label for="onRemote">{{ isRemote ? 'Télétravail' : 'Sur site' }}</label>
          <InputSwitch v-if="localMission.currentCra.timesheet.length > 1" id="onRemote" v-model="isRemote" @update:modelValue="onIsRemoteChange"/>
        </div>
        <p class="mb-0">{{ localMission.jsonCost.negotiatedRateUnit.toUpperCase() }}</p>
      </div>

      <div class="wrapper" :class="{'wrapper-browser': getIsBrowserApp, 'wrapper-mobile': !getIsBrowserApp,}">
        <template v-if="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
          || localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE">
          <!--
                  Technique -> le fond du wrapper est colore du même fond que les bordures pour avoir des bordures collapse (fonctionne avec des couleurs pleine).
                  En mettant un polygon dans la case on montre l'arriere plan du polygon, donc changement de la couleur de fond en blanc quand y'a un poly.
                  Pour garder le click sur l'ensenble de la div ajout d'un polygon inverse lorsque la bordure blanche est visible
          -->
          <div v-for="(dayDate, indexDay) in craDate.days" :key="dayDate" class="grid-item relative z-0"
               :style="getDayStyle(localMission.currentCra.timesheet[currentTimeSheetIndex], dayDate, indexDay)"
               @click="clickCRA(indexDay, localMission.currentCra.timesheet[currentTimeSheetIndex])">
            <div v-if="!getIsBrowserApp && isEditable && localMission.currentCra.timesheet[currentTimeSheetIndex].value.every(value => !value) && isFirstEditableDay(indexDay)" class="absolute left-auto right-auto z-3 blob"></div>
            <span class="absolute z-2 top-0 left-0 p-1 pl-2" style="color: black">{{ dayDate.getDate() }}</span>
            <div v-if="localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay] === 2 || localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay] === 3"
                 class="input-cra-tjm absolute"
                 :style="getDayInversePolygonStyle(localMission.currentCra.timesheet[currentTimeSheetIndex], indexDay)">
            </div>
            <div class="input-cra-tjm z-1"
                 :style="(!editableDayByDate(dayDate, indexDay, localMission.currentCra.timesheet[currentTimeSheetIndex])) ?
                  'background-color:  grey' :
                   localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay] ?
                    'background-color: '+ localMission.currentCra.color :
                     getDayBackgroundColor(dayDate)"
                 :class="'CRA' + localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay]">
            </div>
          </div>
        </template>
        <template v-else-if="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE">
          <div v-for="(dayDate, indexDay) in craDate.days" :key="dayDate" class="grid-item box relative z-0"
               :class="{'input-unit': isEditable && editableDayByDate(dayDate, indexDay, localMission.currentCra.timesheet[currentTimeSheetIndex]) && !!localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay]}"
               :style="getDayStyle(localMission.currentCra.timesheet[currentTimeSheetIndex], dayDate, indexDay)">
            <span v-if="!craInputFocused[indexDay]" class="absolute z-2 top-0 left-0 p-1 pl-2 text-md" :class="{'text-sm': !!localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay]}" style="color: black">
              {{ dayDate.getDate() }}
            </span>
            <input v-if="isEditable && editableDayByDate(dayDate, indexDay, localMission.currentCra.timesheet[currentTimeSheetIndex])"
                   v-model="localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay]"
                   type="number"
                   class="input-cra-horaire"
                   :style="getDayStyle(localMission.currentCra.timesheet[currentTimeSheetIndex], dayDate, indexDay)"
                   @focusin="craInputFocused[indexDay] = true"
                   @focusout="craInputFocused[indexDay] = false"
                   @change="computeTotals">
            <div v-else style="color: white;"
                 class="input-cra-tjm text-center">
              {{ `${!localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay] ? '' : localMission.currentCra.timesheet[currentTimeSheetIndex].value[indexDay] + ' h'}` }}
            </div>
          </div>
        </template>
      </div>
    </div>

    <table v-else style="width: 100%;">
      <tr>
        <th class="td-mission-unit">{{ localMission.jsonCost.negotiatedRateUnit.toUpperCase() }}</th>
        <th :class="(isEditable && (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
          || localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE)) ? 'td-label-other' : 'td-label-horaire'"/>
        <th v-if="isEditable && (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
          || localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE)"
            class="td-check-all">
          &nbsp;
        </th>
        <th v-for="day in craDate.days" :key="day">{{ day.getDate() }}</th>
        <th class="px-4">Total</th>
      </tr>
      <tr v-for="(timesheet, index) in localMission.currentCra.timesheet" :key="timesheet">
        <td v-if="index === 0" :rowspan="localMission.currentCra.timesheet.length"
            :style="'background-color: ' + localMission.currentCra.color" style="color: white;"
            class="td-mission-unit pl-2">
          {{ localMission.clientContract.name.toUpperCase() }}
        </td>
        <td :style="'background-color: ' + localMission.currentCra.color" style="color: white;" class="pl-1"
            :class="(timesheet.type === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
            || timesheet.type === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) ? 'td-label-other' : 'td-label-horaire'">
          {{ timesheet.label }}
        </td>
        <td v-if="isEditable && (timesheet.type === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
          || timesheet.type === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE)"
            class="td-check-all">
          <div class="flex justify-content-center align-items-center">
            <Checkbox v-model="checkAll[indexMission * 2 + index]" :binary="true"
                      @change="checkAllTS(timesheet, localMission, indexMission * 2 + index, index)"/>
          </div>
        </td>
        <template v-if="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
         || localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE">
          <td v-for="(dayDate, indexDay) in craDate.days" :key="dayDate" class="p-0"
              :class="isEditable && editableDayByDate(dayDate, indexDay, timesheet) ? 'cursor-pointer' : ''"
              v-tooltip="getDayTooltip(dayDate)"
              @click="clickCRA(indexDay, timesheet)">
            <div class="input-cra-tjm hover transition-duration-200" :class="'CRA' + timesheet.value[indexDay]"
                :style="(!editableDayByDate(dayDate, indexDay, timesheet)) ?
                 'background-color:  grey' :
                  timesheet.value[indexDay] ?
                   'background-color: ' + localMission.currentCra.color : getDayBackgroundColor(dayDate)">
            </div>
          </td>
        </template>
        <template v-else-if="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE">
          <td v-for="(dayDate, indexDay) in craDate.days" :key="dayDate" style="height: 100%; width: 1.5rem;"
              class="p-0">
            <input v-if="isEditable && editableDayByDate(dayDate, indexDay, timesheet)" ref="inputHours" v-model="timesheet.value[indexDay]" type="number" class="input-cra-horaire"
                   :style="(!editableDayByDate(dayDate, indexDay, timesheet)) ?
                    'background-color:  grey' :
                     timesheet.value[indexDay] ?
                      'background-color: ' + localMission.currentCra.color : getDayBackgroundColor(dayDate)"
                   @change="computeTotals" @keydown="(event) => inputHoursKeyPressed(event, indexDay)" @click="(event) => inputHoursClicked(event, indexDay)">
            <div v-else style="text-align: center; color: white;"
                 :style="(!editableDayByDate(dayDate, indexDay, timesheet)) ?
                  'background-color:  grey' :
                   timesheet.value[indexDay] ?
                    'background-color: ' + localMission.currentCra.color : getDayBackgroundColor(dayDate)"
                 class="input-cra-tjm">
              {{ timesheet.value[indexDay] }}
            </div>
          </td>
        </template>
        <td class="td-total">
          <InputNumber style="width: 100%; max-height: 1.7vw" :input-style="{width: '100%', 'max-height': '1.7vw'}"
                       :disabled="true" highlightOnFocus
                       :suffix="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? ' Heures' : ' Jours'"
                       v-model="timesheet.total" :min="0"/>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex";
import IsBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import {getMissionRange} from "@/utils/Misc";

export default {
  name: "CraDetails",
  mixins: [IsBrowserAppMixin],
  emits: ['onComputeTotals'],
  props: {
    mission: {
      type: Object,
      required: true
    },
    indexMission: {
      type: Number,
      required: true
    },
    // see the constants CRA_TYPES
    craType: {
      type: String,
      required: true
    },
    craSaveFlag: {
      type: Boolean
    },
    holidays: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      checkAll: [],
      localCraTimeSheets: [],

      startMissionDate: null,
      endMissionDate: null,

      craInputFocused: [],
      isRemote: false,
      currentTimeSheetIndex: 0,

      localMission: null
    }
  },
  created () {
    this.localMission = JSON.parse(JSON.stringify(this.mission))
    this.startMissionDate = (this.mission.jsonMission?.startingDate) ? new Date(this.mission.jsonMission.startingDate) : null
    this.endMissionDate = (this.mission.jsonMission?.initialEndingDate) ? new Date(this.mission.jsonMission.initialEndingDate) : null
    // save local timeSheets
    this.saveCopyOfCurrentTimeSheets()
    this.onIsRemoteChange()
    // must be at least onSite or onRemote timesheet within the cra
    this.computeTotals()
  },
  watch: {
    'mission': {
      handler () {
        this.localMission = JSON.parse(JSON.stringify(this.mission))
      },
      deep: true
    },
    'craSaveFlag': {
      handler (value) {
        if (value) {
          this.saveCopyOfCurrentTimeSheets()
        }
      }
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      craDate: state => state.cra.date,
      localPay: state => state.pay.pay,
      localFacturations: state => state.declarationFacturation.declarationsFacturation,
      constants: state => state.constants
    }),
    isEditable() {
      return this.isEditableBelongCraType
    },
    isEditableBelongCraType() {
      if (!this.editable) return false
      let isEditable = false
      switch (this.craType) {
        case this.constants.CRA_TYPES.INVOICE:
          isEditable = this.localFacturations.filter((invoice) => {
            return invoice.status !== this.constants.FACTURATION_STATUS.DRAFT
          })?.length === 0 || this.me.role.name === this.constants.ROLES.ADMIN
          break
        case this.constants.CRA_TYPES.PAY:
          isEditable = this.localPay?.status === this.constants.PAY_STATUS.DRAFT
              || this.me.role.name === this.constants.ROLES.ADMIN
          break
        default:
          isEditable = false
          break
      }
      return isEditable
    },
    mappedHolidays () {
      return this.holidays[0]?.dates?.map(publicHoliday => {
        return {
          label: publicHoliday.label,
          date: new Date(publicHoliday.date)
        }
      })
    },
    missionRange () {
      return this.getMissionRange(this.mission, this.constants)
    }
  },
  methods: {
    getMissionRange,
    saveCopyOfCurrentTimeSheets () {
      this.localCraTimeSheets = JSON.parse(JSON.stringify(this.localMission.currentCra.timesheet))
    },
    onIsRemoteChange (isRemote = null) {
      const foundTimesheet = this.mission.currentCra.timesheet.findIndex(timesheet => !isRemote ? timesheet?.label?.toLowerCase() === 'sur site' : timesheet?.label?.toLowerCase() === 'télétravail')
      if (foundTimesheet === -1) return
      if (isRemote === null) this.isRemote = this.mission.currentCra.timesheet[foundTimesheet].label.toLowerCase() === 'télétravail'
      this.currentTimeSheetIndex = foundTimesheet
    },
    isFirstEditableDay (indexDay) {
      return indexDay === this.craDate.days.findIndex(day => this.editableDayByDate(day, indexDay, this.localMission.currentCra.timesheet[this.currentTimeSheetIndex]))
    },
    editableDayByDate(dayDate, indexDay, timesheet) {
      const dayDateFullYear = dayDate.getFullYear()
      const dayDateMonth = dayDate.getMonth()
      const dayDateDay = dayDate.getDate()
      const missionRange = this.missionRange

      if (!missionRange.startingDate) return false
      if (!missionRange.endingDate && this.mission?.jsonMission?.renewal !== this.constants.RENEWAL_TYPE.AUTOMATIC) return false

      if (!this.mission.currentCra.timesheet
          .filter(currentCraTimesheet => currentCraTimesheet.label !== timesheet.label)
          .every(currentCraTimesheet => !currentCraTimesheet.value[indexDay] || currentCraTimesheet.value[indexDay] === 0)
      ) return false

      const isStartingDateInRange = (missionRange.startingDate.getFullYear() < dayDateFullYear
          ? true : (missionRange.startingDate.getFullYear() === dayDateFullYear && missionRange.startingDate.getMonth() < dayDateMonth)
              ? true : missionRange.startingDate.getMonth() === dayDateMonth && missionRange.startingDate.getDate() <= dayDateDay)
      const isEndingDateInRange = (missionRange.endingDate.getFullYear() > dayDateFullYear
          ? true : (missionRange.endingDate.getFullYear() === dayDateFullYear && missionRange.endingDate.getMonth() > dayDateMonth)
              ? true : missionRange.endingDate.getMonth() === dayDateMonth && missionRange.endingDate.getDate() >= dayDateDay)

      if (!isStartingDateInRange) return false

      if (this.mission?.jsonMission?.renewal === this.constants.RENEWAL_TYPE.AUTOMATIC) return true
      return isEndingDateInRange;
    },
    getDayTooltip (dayDate) {
      if (!this.getIsBrowserApp) return null
      const publicHolidayDay = this.getPublicHolidayDay(dayDate)
      if (publicHolidayDay !== undefined) return publicHolidayDay.label
      return null
    },
    getDayBackgroundColor (dayDate) {
      const defaultStyle = 'background-color: '
      if (this.isPublicHolidayDay(dayDate)) return defaultStyle + 'red'
      if (this.isWeekendDay(dayDate)) return defaultStyle + 'lightgrey'
      return defaultStyle + 'white'
    },
    publicHolidayDateCondition (holidayDate, dayDate) {
      return holidayDate.getFullYear() === dayDate.getFullYear() &&
          holidayDate.getMonth() === dayDate.getMonth() &&
          holidayDate.getDate() === dayDate.getDate()
    },
    isPublicHolidayDay (dayDate) {
      return this.mappedHolidays.findIndex(holidayDate => this.publicHolidayDateCondition(holidayDate.date, dayDate)) !== -1
    },
    getPublicHolidayDay (dayDate) {
      return this.mappedHolidays.find(publicHoliday => this.publicHolidayDateCondition(publicHoliday.date, dayDate))
    },
    isWeekendDay(dayDate) {
      // 0 => dimanche ; 6 samedi
      return [0, 6].indexOf(dayDate.getDay()) !== -1
    },
    clickCRA(indexDay, timesheet) {
      if ((!this.isEditable
          || !this.editableDayByDate(this.craDate.days[indexDay], indexDay, timesheet)
      )) {
        return
      }

      // todo: peut être en le passant dans compute total, en modifiant la mission depuis au dessus, pas sur que ça mette a jour la localMission ici par contre ?
      if (timesheet.value[indexDay]) {
        timesheet.value[indexDay] = (timesheet.value[indexDay] + 1) % 4
      } else {
        timesheet.value[indexDay] = 1
      }
      this.computeTotals()
    },
    checkAllTS(timesheet, mission, index, timesheetIndex) {
      if (!this.isEditable) {
        return
      }
      if (this.checkAll[index]) {
        this.craDate.days.forEach((dayDate, indexDay) => {
          if (this.editableDayByDate(dayDate, indexDay, timesheet)) {
            timesheet.value[indexDay] = this.isWeekendDay(dayDate) || this.isPublicHolidayDay(dayDate) ? (timesheet.value[indexDay] !== 0 ? timesheet.value[indexDay] : 0) : 1
          }
        })
      } else {
        timesheet.value = this.localCraTimeSheets[timesheetIndex] ? this.localCraTimeSheets[timesheetIndex].value : []
      }
      this.computeTotals()
    },
    computeTotals() {
      this.$emit('onComputeTotals', { mission: this.localMission, forceMissionUpdate: true })
    },
    getDayStyle (timesheet, dayDate, indexDay) {
      if (!this.editableDayByDate(dayDate, indexDay, timesheet)) {
        return 'background-color: grey'
      }
      if ((this.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
              || this.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) &&
          (timesheet.value[indexDay] === 1 || timesheet.value[indexDay] === 3)) {
        return `background-color: ${this.mission.currentCra.color}`
      }
      if (this.isWeekendDay(dayDate)) {
        return 'background-color: lightgrey'
      }
      return 'background-color: white'
    },
    // eslint-disable-next-line no-unused-vars
    getDayInversePolygonStyle (timesheet, indexDay) {
      if (timesheet.value[indexDay] === 3) {
        return `background-color: white; clip-path: polygon(0% 0%, 0% 100%, 100% 0%);`
      }
    },
    inputHoursKeyPressed (event, indexDay) {
      if (event.code === "Tab") {
        if (this.$refs.inputHours[indexDay + 1] !== null) {
          this.$refs.inputHours[indexDay + 1].focus
        }
      }
    },
    inputHoursClicked (event, indexDay) {
      this.$refs.inputHours[indexDay].focus
    }
  }
}
</script>

<style scoped>
table, th, td {
  border: solid black 1px;
}
table {
  border-collapse: collapse;
}
th {
  background-color: #505050;
  color: white;
  /*min-width: 3vw;*/
  /*width: 3vw;*/
  /*max-width: 3vw;*/
}
tr {
  height: 1.85vw;
}

.CRA2 {
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.CRA3 {
  clip-path: polygon(100% 100%, 0% 100%, 100% 0%);
}

.CRA-INV3 {
  clip-path: polygon(100% 100%, 0% 100%, 100% 0%);
  background-color: var(--primary-color) !important;
}

.CRA-INV2 {
  background-color: white !important;
}

.td-mission-unit {
  text-align: center;
  font-weight: bold;
  min-width: 10%;
  width: 10%;
  max-width: 10%;
}
.td-label-horaire {
  text-align: center;
  min-width: 8%;
  width: 8%;
  max-width: 8%;
}
.td-label-other {
  text-align: center;
  min-width: 6%;
  width: 6%;
  max-width: 6%;
}
.td-check-all {
  min-width: 2%;
  width: 2%;
  max-width: 2%;
}
.td-total {
  min-width: 8%;
  width: 8%;
  max-width: 8%;
}
.input-cra-horaire::-webkit-outer-spin-button, .input-cra-horaire::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-cra-horaire {
  cursor: pointer;
  color: black;
  border:0;
  height: 3.5rem;
  width: 100%;
  min-width: 1.5rem;
  min-height: 2.5rem;
  max-height: 3.5rem;
  text-align: center;
  font-weight: bold;
  -moz-appearance: textfield;
}
.input-cra-horaire:focus-visible {
  outline: var(--primary-color) auto 1px;
}
.input-cra-horaire:focus {
  cursor: text;
  caret-color: black;
}
.input-cra-tjm {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border:0;
  height: 3.5rem;
  width: 100%;
  min-width: 1.5rem;
  min-height: 2.5rem;
  max-height: 3.5rem;
}

.wrapper {
  display: inline-grid;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid black;
  grid-gap: 1px;
}

.wrapper-mobile {
  grid-template-columns: repeat(5,1fr);
  grid-template-rows: repeat(6, 51px);
  grid-auto-rows: minmax(51px, auto);
}

.wrapper-browser {
  grid-template-columns: repeat(31,1fr);
  grid-template-rows: repeat(1, 51px);
  grid-auto-rows: minmax(51px, auto);
}

.wrapper > div {
  background-color: white
}

.blob {
  background: var(--primary-color);
  opacity: 0.7;
  border-radius: 50%;
  height: 16px;
  width: 16px;

  left: calc(50% - 8px) !important;
  top: calc(50% - 8px) !important;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
div .input-unit::after {
  position: absolute;
  top: 1.1rem;
  right: 0.8rem;
}

.input-unit::after {
  content: 'h';
}
</style>
